<script>
  import {
    GridContainer,
    GridColumn,
    Text,
    SubHeader,
    ContentHandler,
    Separator,
    Icon,
    Link,
  } from '@qlean/ui-kit-web-svelte'

  export let cN
  export let question
  export let answer
  export let links = {}
  export let isExpanded
  export let toggleIsExpanded

  const linksCommonProps = {
    target: '_blank',
    rank: Link.RANKS.THEME_PRIMARY,
    backdropColor: Link.BACKDROPS.LIGHT,
  }
  const linksUnits = Object.entries(links).reduce((nextUnits, [key, { href, text }]) => {
    return {
      ...nextUnits,
      [key]: {
        props: { href },
        text,
      },
    }
  }, {})
</script>

<div class={cN('faq__unit', $$props.class)}>
  <Separator key={20} size={Separator.SIZES.SMALL} />
  <div on:click={toggleIsExpanded} class={cN('faq__unit-header')}>
    <GridColumn width={10} widthMiddle={7}>
      <SubHeader size={SubHeader.SIZES.STRONG}>{question}</SubHeader>
    </GridColumn>
    <GridColumn width={2} widthMiddle={1} class={cN('faq__unit-header-arrow-container')}>
      <Icon
        name={Icon.NAMES.arrow}
        key={Icon.KEYS.MEDIUM}
        class={cN('faq__unit-header-arrow', { 'faq__unit-header-arrow--active': isExpanded })} />
    </GridColumn>
  </div>

  <GridColumn
    width={12}
    widthMiddle={7}
    class={cN('faq__unit-content', { 'faq__unit-content--visible': isExpanded })}>
    <Separator key={15} size={Separator.SIZES.SMALL} />
    <ContentHandler
      baseText={{ Component: Text }}
      links={{ Component: Link, commonProps: linksCommonProps, units: linksUnits }}
      content={answer} />
  </GridColumn>
  <Separator key={20} size={Separator.SIZES.SMALL} />
</div>
