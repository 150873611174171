<script>
  import {
    GridContainer,
    GridColumn,
    Header,
    Separator,
    ButtonTertiary,
    Icon,
    WidgetHeader,
    WidgetFooter,
  } from '@qlean/ui-kit-web-svelte'
  import classNames from 'classnames/bind'

  import Row from './Row'
  import css from './style.scss'

  const cN = classNames.bind(css)

  export let config

  const { title, titleTag, subTitle, list = [], button, withTopMargin, withBottomMargin } = config

  let expandedIdx = 0
  function toggleIsExpanded(idx) {
    expandedIdx = idx === expandedIdx ? null : idx
  }
</script>

{@html Icon.spriteContent}

<WidgetHeader {title} {titleTag} {subTitle} withMargin={withTopMargin} />
<GridContainer class={cN('faq')}>
  <GridColumn width={0} widthMiddle={2} />
  <GridColumn widthMiddle={8}>
    {#each list as { question, answer, links }, unitIdx}
      <Row
        class={cN({ 'faq__unit--last': unitIdx === list.length - 1 })}
        {cN}
        {question}
        {answer}
        {links}
        isExpanded={unitIdx === expandedIdx}
        toggleIsExpanded={toggleIsExpanded.bind(null, unitIdx)} />
    {/each}
  </GridColumn>
  <GridColumn width={0} widthMiddle={2} />

  {#if button !== undefined}
    <GridColumn class={cN('faq__all-link-container')}>
      <Separator key={20} size={Separator.SIZES.LARGE} />
      <ButtonTertiary isLink href={button.href} rounded width={ButtonTertiary.WIDTHS.MEDIUM}>
        {button.text}
      </ButtonTertiary>
    </GridColumn>
  {/if}
</GridContainer>
<WidgetFooter withMargin={withBottomMargin} />
